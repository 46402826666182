import React from "react";
import styles from "./SearchTickets.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import AddNote from "../../components/AddNote";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITY_HOLDING} from "../../AppConstants";

const SearchTickets = (props) => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [birthday, setBirthday] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [holding, setHolding] = React.useState(0);
    const [entries, setEntries] = React.useState([]);
    const [workstates,setWorkStates] = React.useState({});
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [searchdone,setSearchDone] = React.useState(false);
    const [shownote,setShowNote] = React.useState(false);
    const [showhold,setShowHold] = React.useState(false);
    const [showclose,setShowClose] = React.useState(false);
    const [ischild,setIsChild] = React.useState(props.ischild || false);

    const dummy = () => {}

    const onCancel = (e) => {
        const cb = props.oncancel || dummy;
        cb();
    }


    const handleSubmit = (id) => {
        // Call server component to retrieve list of entries based on form input
        // Simulated server call
        console.log('Form submitted with:', { firstName, lastName, birthday, phoneNumber });
        let haveentry = firstName.length > 0 | lastName.length > 0 | birthday.length > 0 | phoneNumber.length > 0;
        if (!haveentry) {
            alert("At least 1 item needs to be filled in. Cancelling search.");
            return;
        }
        setSelectedEntry(null);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        let sdob = "";
        if (birthday.length > 0) {
            sdob = CleanDate(birthday);
            console.log("DOB = "+sdob);
            if (!sdob.length) {
                alert("Invalid DOB.");
                return;
            }
        }
        let sphone = "";
        if (phoneNumber.length > 0) {
            sphone = PhoneTest(phoneNumber);
            if (!sphone.length) {
                alert("Invalid Phone Number.");
                return;
            }
        }
        dataservice.searchtickets(holding,firstName,lastName,sdob,sphone).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setEntries(data.TICKETS);
            let states = data.STATES || [];
            let indexstates = {};
            states.forEach((st)=> {
                indexstates[st.WorkFlowStateID] = st.Name;
            })
            setWorkStates(indexstates);
            setSearchDone(true);
        })
      };
    
    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setSelectedEntry(data.TICKET);
            /*
            const wf = data.TICKET.workflow || [];
            wf.forEach((ws)=> {
                if (ws.buttontext.toLowerCase()==='in-progress') {
                    setIPState(ws.workflowstateid);
                }
            })
            setShowTicket(true);
            */
        })
    };


    const cancelNote = () => {
        setShowNote(false);
    }

    const saveNote = (reason,cmt) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        //cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');


        let obj = {
            ticketid:selectedEntry.ticketid,
            msgid:selectedEntry.msgid,
            msgtype:selectedEntry.msgtype,
            msgdt:"",
            respid:props.globals.useraccountid,
            resptype:'R',
            uaid:props.globals.useraccountid,
            actionid:ACTIONADDNOTE,
            note:cmt
        }
        dataservice.addticketnote(obj).then((data) => {
                if (data.ERROR) {
                    alert(data.ERROR);
                } else {
                    selectedEntry.notes = data.NOTES;
                    setShowNote(false);
                }
            })
        
    }

    const setAddNote = (e) => {
        setShowNote(true);
    }

    const SetCloseOK = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.setticketevent(selectedEntry.ticketid,selectedEntry.finalstateid,selectedEntry.priority,props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                let newentries = JSON.parse(JSON.stringify(entries));
                let selid = selectedEntry.ticketid;
                newentries = newentries.filter(obj=>obj.ticketid !== selid);
                setSelectedEntry(null);
                setEntries(newentries)
            }
            setShowClose(false);
        })
    }

    const SetCloseCancel = (id) => {
        setShowClose(false);
    }

    const SetClose = (id) => {
        setShowClose(true);
    }

    const SetHoldOK = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.settickethold(selectedEntry.ticketid,1,props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                selectedEntry.priority = PRIORITY_HOLDING;
            }
            setShowHold(false);
        })
    }

    const SetHoldCancel = (id) => {
        setShowHold(false);
    }

    const SetHold = (id) => {
        setShowHold(true);
    }

    const ShowResults = () => {

        return (
            <React.Fragment>
                <div className={styles.title} style={{marginTop:"20px"}}>Search Results 
                {holding===0 && <span style={{fontSize:"14px",fontWeight:"normal",marginLeft:"16px"}}>(Yellow background indicates ticket is in a Holding state)</span>}
                </div>
                <table className={styles.tickettable}>
                    <tr>
                        <th>Ticket #</th>
                        <th>Message Type</th>
                        <th>Created Date</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>DOB</th>
                        <th>Phone</th>
                        <th>Practice</th>
                    </tr>
                    {entries.map((entry,i)=> {
                        let phones = '';
                        let bg = "white";
                        if (entry.priority===PRIORITY_HOLDING && holding===0) {
                            bg = "yellow";
                        }
                        let rowbg = "white";
                        if (entry===selectedEntry) {
                            rowbg = "lightgreen";
                        }
                        if (entry.callbackphone.length > 0) {
                            phones = formatPhoneNumber(entry.callbackphone);
                        }
                        if (entry.secondaryphone.length > 0) {
                            if (phones.length > 0) phones += " , "
                            phones += formatPhoneNumber(entry.secondaryphone);
                        }
                        return (
                            <tr key={"phone_"+i} className={styles.trow} style={{backgroundColor:rowbg}} onClick={()=>handleEntryClick(entry)}>
                                <td className={styles.tcellfirst}>{entry.ticketid}</td>
                                <td className={styles.tcell}>{entry.msgname}</td>
                                <td className={styles.tcell}>{entry.createdate}</td>
                                <td className={styles.tcell} style={{backgroundColor:bg}}>{entry.firstname}</td>
                                <td className={styles.tcell} style={{backgroundColor:bg}}>{entry.lastname}</td>
                                <td className={styles.tcell}>{DateOnly(entry.dob)}</td>
                                <td className={styles.tcell}>{phones}</td>
                                <td className={styles.tcell}>{entry.pracname}</td>
                            </tr>
                        )
                    })}
                </table>
            </React.Fragment>
        )
    }

    const ShowButtons = () => {

        if (selectedEntry === null) {
            return null;
        }
        let showhold = false;
        if (holding===0 && selectedEntry.priority !== PRIORITY_HOLDING) {
            showhold = true;
        }
        let isescalated = selectedEntry.statename==='ESCALATE';
        
        const note = selectedEntry.notes || "";
        let notes = note.split('\n').filter(Boolean);
        return (
            <React.Fragment>
                <div style={{display:"flex",marginTop:"12px",marginLeft:"10px"}}>
                    <div style={{display:"flex"}}>
                        <div><GenButton buttontext="Add Note" width="110" onclick={setAddNote} fontsize="16px" /></div>
                        {showhold && <div style={{marginLeft:"16px"}}><GenButton buttontext="Place on Hold" width="110" onclick={SetHold} fontsize="16px" /></div>}
                        {isescalated && <div style={{display:"flex"}}>
                            <div style={{marginLeft:"16px"}}><GenButton buttontext="Put in Queue" width="110" onclick={onCancel} fontsize="16px" /></div>
                            <div style={{marginLeft:"16px"}}><GenButton buttontext="Take Ownership" width="110" onclick={onCancel} fontsize="16px" /></div>
                            </div>}
                        <div style={{marginLeft:"16px"}}><GenButton buttontext="Close Ticket" width="110" onclick={SetClose} fontsize="16px" /></div>
                        {ischild && <div style={{marginLeft:"16px"}}><GenButton buttontext="Cancel Call" width="110" onclick={onCancel} fontsize="16px" /></div>}
                    </div>

                    <div style={{width:"700px",marginLeft:"20px",fontSize:"14px", display: 'flex', flexDirection: 'column',alignItems:"flex-start",fontWeight:"bold"}}>Notes:
                        {notes.map((n,i)=> {
                            return (
                                <div key={i} style={{fontWeight:"normal"}}>{n}</div>
                            )
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const ticketOnClose = (ticketid,stateid,priority) => {
        handleSubmit(0);
        //setSelectedEntry(null);
    }

    const noresult = entries.length===0 && searchdone===true;
    return (
        <React.Fragment>
            <div><div className={styles.title}>Search Tickets</div>
            <div className={styles.page}>
                <div className={styles.form} style={{marginTop:"40px"}}>
                    <label className={styles.formlabel}>
                        First Name:
                        <input className={styles.forminput} type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        Last Name:
                        <input className={styles.forminput} type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        DOB:
                        <input className={styles.forminput} type="date" value={birthday} onChange={e => setBirthday(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        Phone Number:
                        <input className={styles.forminput} type="tel" placeholder="518 555-1212" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                    </label>
                    <br />
                    <div style={{marginLeft:"200px",display:"flex"}}>
                        <GenButton buttontext="Submit" width="80" onclick={handleSubmit} fontsize="18px" />
                        {ischild && <div style={{marginLeft:"16px"}}><GenButton buttontext="Cancel Call" width="110" onclick={onCancel} fontsize="16px" /></div>}
                    </div>
                    
                </div>
                <div style={{marginTop:"20px",fontSize:"18px",lineHeight:"16px"}}>
                    <p>Fill in as many fields as possible</p><p>to perform a better search.</p>
                    <p>First and Last names can be partial text.</p><p>Birthday and Phone Number must be complete.</p>
                    <p>At least 1 field must be entered to search.</p>
                </div>
                </div>
                <div>
                    {entries.length > 0 && <ShowResults />}
                    {noresult && <div style={{marginTop:"20px",marginLeft:"20px",fontSize:"18px"}}>No results found.</div>}
                </div>
                {/*<ShowButtons />*/}
                {selectedEntry && <TicketDetail 
                                    globals={props.globals} 
                                    methods={props.methods} 
                                    allowpriorityedit={false} 
                                    fromsearch={true} 
                                    ticket={selectedEntry} 
                                    doClose={ticketOnClose} 
                                    closeticket={SetCloseOK} 
                                    />}
                {shownote && <AddNote globals={props.globals} methods={props.methods} showreason={false} onSave={saveNote} onCancel={cancelNote} />}
                {showhold && <GenConfirm 
                    globals={props.globals} 
                    methods={props.methods}
                    width="400"
                    text="Place the selected ticket on Hold?"
                    onCancel = {SetHoldCancel}
                    onConfirm = {SetHoldOK}
                    cancelText = "No"
                    confirmText = "Yes" />}
                {showclose && <GenConfirm 
                    globals={props.globals} 
                    methods={props.methods}
                    width="400"
                    text="Close the selected ticket?"
                    onCancel = {SetCloseCancel}
                    onConfirm = {SetCloseOK}
                    cancelText = "No"
                    confirmText = "Yes" />}
            </div>

        </React.Fragment>

    )
}

export default SearchTickets;