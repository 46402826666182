import React from "react";
import styles from "./SystemLists.module.scss";
import { DataService } from "../../store/DataService";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";

var origcreds = [];
var origspecs = [];
var origsys = [];
var originfotypes = [];
var origmsgtypes = [];
var newmenuid = "";

const SystemLists = (props) => {
    const [menuid,setMenuID] = React.useState(0);
    const [showdelcred,setShowDelCred] = React.useState(false);
    const [showdelspec,setShowDelSpec] = React.useState(false);
    const [showdelsys,setShowDelSys] = React.useState(false);
    const [showdelit,setShowDelIT] = React.useState(false);
    const [showchangemenu,setShowChangeMenu] = React.useState(false);
    const [dirtycreds,setDirtyCreds] = React.useState(false);
    const [dirtyspecs,setDirtySpecs] = React.useState(false);
    const [dirtysys,setDirtySys] = React.useState(false);
    const [dirtyitypes,setDirtyITypes] = React.useState(false);
    const [dirtymtypes,setDirtyMTypes] = React.useState(false);
    const [matrix,setMatrix] = React.useState({
        credentials:[],
        specialties:[],
        infotypes:[],
        msgtypes:[]
    })


    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getsystemlists().then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }

            setMatrix(data.matrix);
            origcreds = JSON.parse(JSON.stringify(data.matrix.credentials));
            origspecs = JSON.parse(JSON.stringify(data.matrix.specialties));
            originfotypes = JSON.parse(JSON.stringify(data.matrix.infotypes));
            origsys = JSON.parse(JSON.stringify(data.matrix.systems));
            origmsgtypes = JSON.parse(JSON.stringify(data.matrix.msgtypes));
            setMenuID("m1");
        })
    },[props.globals.sessionguid])

    const compareObj = (o1, o2) => {
        var p,p1,p2;
        for(p in o1){
            if(o1.hasOwnProperty(p)){
                p1 = o1[p];
                p2 = o2[p];
                if(p1 !== p2){
                    return false;
                }
            }
        }
        for(p in o2){
            if(o2.hasOwnProperty(p)){
                if(o1[p] !== o2[p]){
                    return false;
                }
            }
        }
        return true;
    };

    const compareLists = (l1,l2) => {
        var result = true;
        if (l1.length === l2.length) {
            for (let i=0;i<l1.length;i++) {
                let obj1 = l1[i];
                let obj2 = l2[i];
                let r = compareObj(obj1,obj2);
                if (!r) {
                    result = false;
                    break;
                }
            }
        } else {
            result = false;
        }
        return result;
    }

    const credChange = (e) => {
        let cid = e.target.id;
        let chk = e.target.checked;
        let value = e.target.value;
        let sa = cid.split('_');
        if (sa.length > 1) {
            if (origcreds.length < 1) {
                origcreds = JSON.parse(JSON.stringify(matrix.credentials));
            }
            let found = false;
            let credid = sa[1];
            let creds = JSON.parse(JSON.stringify(matrix.credentials));
            for (let i=0;i < creds.length;i++) {
                let cred = creds[i];
                let orgcred = origcreds[i];
                if (cred.credentialsid===credid) {
                    found = true;
                    if (sa[0]==='cb') {
                        cred.delflag = chk;
                        cred.updflag = cred.delflag !== orgcred.delflag;
                    }
                    if (sa[0]==='act') {
                        cred.active = chk ? 'Y' : 'N';
                        cred.updflag = cred.active !== orgcred.active;
                    }
                    if (sa[0]==='inp') {
                        cred.description = value;
                        cred.updflag = cred.description !== orgcred.description;
                    }
                    break;
                }
            }
            if (found) {
                setMatrix(values => ({...values,credentials:creds}));
                setDirtyCreds(!compareLists(creds,origcreds))
            }
        }
    }

    const countCredDeletes = () => {
        var count = 0;
        let creds = matrix.credentials || [];
        for (let i = 0;i < creds.length;i++) {
            if (creds[i].delflag) count += 1;
        }
        return count;
    }

    const saveCredsOK = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        setShowDelCred(false);
        dataservice.savecreds(props.globals.useraccountid,matrix.credentials).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let creds = data.creds || [];
            setMatrix(values => ({...values,credentials:creds}));
            origcreds = JSON.parse(JSON.stringify(creds));
            setDirtyCreds(false);
        })
    }

    const saveCredsCancel = (e) => {
        setShowDelCred(false);
    }

    const saveCreds = () => {
        if (countCredDeletes() > 0) {
            setShowDelCred(true);
        } else {
            saveCredsOK(null);
        }
    }

    const addCred = () => {
        let oldcreds = JSON.parse(JSON.stringify(matrix.credentials));
        let minid = 0;
        for (let i=0;i<oldcreds.length;i++) {
            if (oldcreds[i].credentialsid < minid) minid = oldcreds[i].credentialsid;
        }
        minid -= 1;
        let cred = {
            credentialsid: ''+minid,
            delflag:false,
            updflag:true,
            active:'Y',
            description:""
        }
        oldcreds.push(cred);
        origcreds.push(cred);
        setMatrix(values => ({...values,credentials:oldcreds}));
        setDirtyCreds(true);
    }

    const ShowCredentials = () => {
        const credlist = matrix.credentials || [];
        return (
            <div key={"showcr"} className={styles.rightbox}>
                <div className={styles.boxtitle}>Credentials</div>
                <div className={styles.boxhdr3}>
                    <div>Delete</div>
                    <div>Description</div>
                    <div>Active</div>
                </div>
                {credlist.map((cred,i)=> {
                    return (
                        <div key={"cr_"+i} className={styles.box3}>
                            <div key={"divcrdel_"+i}><input key={"crdel_"+i} type="checkbox" id={"cb_"+cred.credentialsid} checked={cred.delflag} onChange={credChange}></input></div>
                            <div key={"divcrinp_"+i}><input key={"crinp_"+i} type="text" maxLength="10" style={{width:"140px"}} id={"inp_"+cred.credentialsid} onChange={credChange} value={cred.description}></input></div>
                            <div key={"divcract_"+i}><input key={"cract_"+i} type="checkbox" id={"act_"+cred.credentialsid} checked={cred.active==='Y'} onChange={credChange}></input></div>
                        </div>
                    )
                })}
                <div style={{width:"280px",marginLeft:"5px",marginTop:"10px",paddingBottom:"20px",display:"flex"}}>
                    <GenButton width="120" buttontext="Save" disabled={!dirtycreds} onclick={saveCreds} />
                    <div style={{marginLeft:"10px"}}>
                        <GenButton width="100" buttontext="Add New" onclick={addCred} />
                    </div>
                </div>

            </div>
        )
    }

        //-----------------------------------------------------------
        const sysChange = (e) => {
            let cid = e.target.id;
            let chk = e.target.checked;
            let value = e.target.value;
            let sa = cid.split('_');
            if (sa.length > 1) {
                if (origsys.length < 1) {
                    origsys = JSON.parse(JSON.stringify(matrix.systems));
                }
                let found = false;
                let sid = sa[1];
                let sys = JSON.parse(JSON.stringify(matrix.systems));
                for (let i=0;i < sys.length;i++) {
                    let ss = sys[i];
                    let orgsys = origsys[i];
                    if (''+ss.systemid===''+sid) {
                        found = true;
                        if (sa[0]==='sycb') {
                            ss.delflag = chk;
                            ss.updflag = ss.delflag !== orgsys.delflag;
                        }
                        if (sa[0]==='syact') {
                            ss.active = chk ? 'Y' : 'N';
                            ss.updflag = ss.active !== orgsys.active;
                        }
                        if (sa[0]==='syinp') {
                            ss.description = value;
                            ss.updflag = ss.description !== orgsys.description;
                        }
                        break;
                    }
                }
                if (found) {
                    setMatrix(values => ({...values,systems:sys}));
                    setDirtySys(!compareLists(sys,origsys))
                }
            }
        }
    
        const countSysDeletes = () => {
            var count = 0;
            let sys = matrix.systems || [];
            for (let i = 0;i < sys.length;i++) {
                if (sys[i].delflag) count += 1;
            }
            return count;
        }
    
        const saveSysOK = (e) => {
            const dataservice = new DataService({guid:props.globals.sessionguid});
            setShowDelSys(false);
            dataservice.savesys(props.globals.useraccountid,matrix.systems).then((data)=> {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                let sys = data.systems || [];
                setMatrix(values => ({...values,systems:sys}));
                origspecs = JSON.parse(JSON.stringify(sys));
                setDirtySys(false);
            })
        }
    
        const saveSysCancel = (e) => {
            setShowDelSys(false);
        }
    
        const saveSys = () => {
            if (countSysDeletes() > 0) {
                setShowDelSys(true);
            } else {
                saveSysOK(null);
            }
        }
    
        const addSys = () => {
            let oldsys = JSON.parse(JSON.stringify(matrix.systems));
            let minid = 0;
            for (let i=0;i<oldsys.length;i++) {
                if (oldsys[i].systemid < minid) minid = oldsys[i].systemid;
            }
            minid -= 1;
            let sys = {
                systemid: ''+minid,
                delflag:false,
                updflag:true,
                active:'Y',
                description:""
            }
            oldsys.push(sys);
            origsys.push(sys);
            setMatrix(values => ({...values,systems:oldsys}));
            setDirtySys(true);
        }
    
        const ShowSystems = () => {
            const syslist = matrix.systems || [];
            return (
                <div key={"showcr"} className={styles.rightbox}>
                    <div className={styles.boxtitle}>Systems</div>
                    <div className={styles.boxhdr3}>
                        <div>Delete</div>
                        <div>Description</div>
                        <div>Active</div>
                    </div>
                    {syslist.map((sys,i)=> {
                        return (
                            <div key={"sp_"+i} className={styles.box3}>
                                <div key={"divsydel_"+i}><input key={"sydel_"+i} type="checkbox" id={"sycb_"+sys.systemid} checked={sys.delflag} onChange={sysChange}></input></div>
                                <div key={"divsyinp_"+i}><input key={"syinp_"+i} type="text" maxLength="50" style={{width:"140px"}} id={"syinp_"+sys.systemid} onChange={sysChange} value={sys.description}></input></div>
                                <div key={"divsyact_"+i}><input key={"syact_"+i} type="checkbox" id={"syact_"+sys.systemid} checked={sys.active==='Y'} onChange={sysChange}></input></div>
                            </div>
                        )
                    })}
                    <div style={{width:"280px",marginLeft:"5px",marginTop:"10px",paddingBottom:"20px",display:"flex"}}>
                        <GenButton width="120" buttontext="Save" disabled={!dirtysys} onclick={saveSys} />
                        <div style={{marginLeft:"10px"}}>
                            <GenButton width="100" buttontext="Add New" onclick={addSys} />
                        </div>
                    </div>
    
                </div>
            )
        }
    

    //-----------------------------------------------------------
    const specChange = (e) => {
        let cid = e.target.id;
        let chk = e.target.checked;
        let value = e.target.value;
        let sa = cid.split('_');
        if (sa.length > 1) {
            if (origspecs.length < 1) {
                origspecs = JSON.parse(JSON.stringify(matrix.specialties));
            }
            let found = false;
            let specid = sa[1];
            let specs = JSON.parse(JSON.stringify(matrix.specialties));
            for (let i=0;i < specs.length;i++) {
                let spec = specs[i];
                let orgspec = origspecs[i];
                if (spec.specialtyid===specid) {
                    found = true;
                    if (sa[0]==='spcb') {
                        spec.delflag = chk;
                        spec.updflag = spec.delflag !== orgspec.delflag;
                    }
                    if (sa[0]==='spact') {
                        spec.active = chk ? 'Y' : 'N';
                        spec.updflag = spec.active !== orgspec.active;
                    }
                    if (sa[0]==='spinp') {
                        spec.description = value;
                        spec.updflag = spec.description !== orgspec.description;
                    }
                    break;
                }
            }
            if (found) {
                setMatrix(values => ({...values,specialties:specs}));
                setDirtySpecs(!compareLists(specs,origspecs))
            }
        }
    }

    const countSpecDeletes = () => {
        var count = 0;
        let specs = matrix.specialties || [];
        for (let i = 0;i < specs.length;i++) {
            if (specs[i].delflag) count += 1;
        }
        return count;
    }

    const saveSpecsOK = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        setShowDelSpec(false);
        dataservice.savespecs(props.globals.useraccountid,matrix.specialties).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let specs = data.specs || [];
            setMatrix(values => ({...values,specialties:specs}));
            origspecs = JSON.parse(JSON.stringify(specs));
            setDirtySpecs(false);
        })
    }

    const saveSpecsCancel = (e) => {
        setShowDelSpec(false);
    }

    const saveSpecs = () => {
        if (countSpecDeletes() > 0) {
            setShowDelSpec(true);
        } else {
            saveSpecsOK(null);
        }
    }

    const addSpec = () => {
        let oldspecs = JSON.parse(JSON.stringify(matrix.specialties));
        let minid = 0;
        for (let i=0;i<oldspecs.length;i++) {
            if (oldspecs[i].specialtyid < minid) minid = oldspecs[i].specialtyid;
        }
        minid -= 1;
        let spec = {
            specialtyid: ''+minid,
            delflag:false,
            updflag:true,
            active:'Y',
            description:""
        }
        oldspecs.push(spec);
        origspecs.push(spec);
        setMatrix(values => ({...values,specialties:oldspecs}));
        setDirtySpecs(true);
    }

    const ShowSpecialties = () => {
        const speclist = matrix.specialties || [];
        return (
            <div key={"showcr"} className={styles.rightbox}>
                <div className={styles.boxtitle}>Specialties</div>
                <div className={styles.boxhdr3}>
                    <div>Delete</div>
                    <div>Description</div>
                    <div>Active</div>
                </div>
                {speclist.map((spec,i)=> {
                    return (
                        <div key={"sp_"+i} className={styles.box3}>
                            <div key={"divspdel_"+i}><input key={"spdel_"+i} type="checkbox" id={"spcb_"+spec.specialtyid} checked={spec.delflag} onChange={specChange}></input></div>
                            <div key={"divspinp_"+i}><input key={"spinp_"+i} type="text" maxLength="50" style={{width:"140px"}} id={"spinp_"+spec.specialtyid} onChange={specChange} value={spec.description}></input></div>
                            <div key={"divspact_"+i}><input key={"spact_"+i} type="checkbox" id={"spact_"+spec.specialtyid} checked={spec.active==='Y'} onChange={specChange}></input></div>
                        </div>
                    )
                })}
                <div style={{width:"280px",marginLeft:"5px",marginTop:"10px",paddingBottom:"20px",display:"flex"}}>
                    <GenButton width="120" buttontext="Save" disabled={!dirtyspecs} onclick={saveSpecs} />
                    <div style={{marginLeft:"10px"}}>
                        <GenButton width="100" buttontext="Add New" onclick={addSpec} />
                    </div>
                </div>

            </div>
        )
    }

    //-----------------------------------------------------------
    const itChange = (e) => {
        let cid = e.target.id;
        let chk = e.target.checked;
        let value = e.target.value;
        let sa = cid.split('_');
        if (sa.length > 1) {
            if (originfotypes.length < 1) {
                originfotypes = JSON.parse(JSON.stringify(matrix.infotypes));
            }
            let found = false;
            let itypeid = sa[1];
            let itypes = JSON.parse(JSON.stringify(matrix.infotypes));
            for (let i=0;i < itypes.length;i++) {
                let itype = itypes[i];
                let orgitype = originfotypes[i];
                if (''+itype.info_typesid===itypeid) {
                    found = true;
                    if (sa[0]==='itcb') {
                        itype.delflag = chk;
                        itype.updflag = itype.delflag !== orgitype.delflag;
                    }
                    if (sa[0]==='itact') {
                        itype.active = chk ? 'Y' : 'N';
                        itype.updflag = itype.active !== orgitype.active;
                    }
                    if (sa[0]==='itsort') {
                        itype.sortorder = value;
                        itype.updflag = itype.sortorder !== orgitype.sortorder;
                    }
                    if (sa[0]==='itinp') {
                        itype.description = value;
                        itype.updflag = itype.description !== orgitype.description;
                    }
                    if (sa[0]==='italpha') {
                        itype.alphapagelabel = value;
                        itype.updflag = itype.alphapagelabel !== orgitype.alphapagelabel;
                    }
                    break;
                }
            }
            if (found) {
                setMatrix(values => ({...values,infotypes:itypes}));
                setDirtyITypes(!compareLists(itypes,originfotypes))
            }
        }
    }

    const countITDeletes = () => {
        var count = 0;
        let itypes = matrix.infotypes || [];
        for (let i = 0;i < itypes.length;i++) {
            if (itypes[i].delflag) count += 1;
        }
        return count;
    }

    const saveITOK = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        setShowDelIT(false);
        dataservice.saveinfotypes(props.globals.useraccountid,matrix.infotypes).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            let itypes = data.infotypes || [];
            setMatrix(values => ({...values,infotypes:itypes}));
            originfotypes = JSON.parse(JSON.stringify(itypes));
            setDirtyITypes(false);
        })
    }

    const saveITCancel = (e) => {
        setShowDelIT(false);
    }

    const saveIT = () => {
        if (countITDeletes() > 0) {
            setShowDelIT(true);
        } else {
            saveITOK(null);
        }
    }

    const addIT = () => {
        let olditypes = JSON.parse(JSON.stringify(matrix.infotypes));
        let minid = 0;
        for (let i=0;i<olditypes.length;i++) {
            if (olditypes[i].info_typesid < minid) minid = olditypes[i].info_typesid;
        }
        minid -= 1;
        let itype = {
            info_typesid: ''+minid,
            delflag:false,
            updflag:true,
            active:'Y',
            description:"",
            sortorder:"",
            alphapagelabel:"",
            ask:""
        }
        olditypes.push(itype);
        originfotypes.push(itype);
        setMatrix(values => ({...values,infotypes:olditypes}));
        setDirtyITypes(true);
    }


    const ShowInfoTypes = () => {
        const itlist = matrix.infotypes || [];
        return (
            <div key={"showcr"} className={styles.rightbox}>
                <div className={styles.boxtitle}>Additional Message Items</div>
                <div className={styles.boxhdr5}>
                    <div>Delete</div>
                    <div>Sort Order</div>
                    <div>Description</div>
                    <div>Alpha Page Label</div>
                    <div>Active</div>
                </div>
                {itlist.map((itype,i)=> {
                    return (
                        <div key={"it_"+i} className={styles.box5}>
                            <div key={"divitdel_"+i}><input key={"itdel_"+i} type="checkbox" id={"itcb_"+itype.info_typesid} checked={itype.delflag} onChange={itChange}></input></div>
                            <div key={"divitsort_"+i}><input key={"itsort_"+i} type="text" maxLength="5" style={{width:"140px"}} id={"itsort_"+itype.info_typesid} onChange={itChange} value={itype.sortorder}></input></div>
                            <div key={"divitinp_"+i}><input key={"itinp_"+i} type="text" maxLength="50" style={{width:"140px"}} id={"itinp_"+itype.info_typesid} onChange={itChange} value={itype.description}></input></div>
                            <div key={"divitalpha_"+i}><input key={"italpha_"+i} type="text" maxLength="50" style={{width:"140px"}} id={"italpha_"+itype.info_typesid} onChange={itChange} value={itype.alphapagelabel}></input></div>
                            <div key={"divitact_"+i}><input key={"itact_"+i} type="checkbox" id={"itact_"+itype.info_typesid} checked={itype.active==='Y'} onChange={itChange}></input></div>
                        </div>
                    )
                })}
                <div style={{width:"280px",marginLeft:"5px",marginTop:"10px",paddingBottom:"20px",display:"flex"}}>
                    <GenButton width="120" buttontext="Save" disabled={!dirtyitypes} onclick={saveIT} />
                    <div style={{marginLeft:"10px"}}>
                        <GenButton width="100" buttontext="Add New" onclick={addIT} />
                    </div>
                </div>

            </div>
        )        
    }

    const saveMT = (e) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.savemsgtypes(props.globals.useraccountid,matrix.msgtypes).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setDirtyMTypes(false);
        })
    }

    var noblur = false;
    const ShowMsgTypes = () => {
        
        const mtlist = matrix.msgtypes || [];

        const checkTime = (tm) => {

            const sa = tm.split(":");
            if (sa.length < 2) {
                return "Invalid time format. Use HH:MM";
            }
            const hr = sa[0];
            const mn = sa[1];
            if (isNaN(hr)) {
                return "Hour is not a number. Use HH:MM";
            }
            if (isNaN(mn)) {
                return "Minute is not a number. Use HH:MM";
            }
            const ihr = parseInt(hr);
            const imn = parseInt(mn);
            if (ihr < 0 || ihr > 23) {
                return "Hour is invalid. Must be 0 to 23";
            }
            if (imn < 0 || imn > 59){
                return "Minute is invalid. Must be 0 to 23";
            }
            

            return "";
        }

        const onTimeBlur = (e) => {
            if (noblur) return;
            const id = e.target.id || "";
            const value = e.target.value || "";
            if (value.length > 0) {
                const errs = checkTime(value);
                if (errs.length) {
                    e.preventDefault();
                    e.target.focus();
                }
            }
        }

        const mtChange = (e) => {
            const id = e.target.id || "";
            const value = e.target.value || "";
            const chk = e.target.checked || false;
            const sa = id.split("_");
            if (sa.length > 1) {
                if (origmsgtypes.length < 1) {
                    origmsgtypes = JSON.parse(JSON.stringify(matrix.msgtypes));
                }
                let found = false;
                let mtid = sa[1];
                let mtypes = JSON.parse(JSON.stringify(matrix.msgtypes));
                for (let i=0;i < mtypes.length;i++) {
                    let mt = mtypes[i];
                    let orgmtype = origmsgtypes[i];
                    if (''+mt.msgtypeid===mtid) {
                        found = true;
                        if (sa[0]==='mtact') {
                            mt.active = chk ? 'Y' : 'N';
                            mt.updflag = mt.active !== orgmtype.active;
                        }
                        if (sa[0]==='mtstart') {
                            mt.starttime = value;
                            mt.updflag = mt.starttime !== orgmtype.starttime;
                        }
                        if (sa[0]==='mtend') {
                            mt.endtime = value;
                            mt.updflag = mt.endtime !== orgmtype.endtime;
                        }
                        if (sa[0]==='mtpri') {
                            mt.priority = value;
                            mt.updflag = mt.priority !== mt.priority;
                        }
                        break;
                    }
                }
                if (found) {
                    setMatrix(values => ({...values,msgtypes:mtypes}));
                    setDirtyMTypes(!compareLists(mtypes,origmsgtypes))
                }
    
            }
        }

        const ShowPriorities = ({mt}) => {
            const mtid = mt.msgtypeid || 0;
            const pri = mt.priority || 0;
            return (
                <select id={'mtpri_'+mtid} value={pri} onChange={mtChange}>
                    <option value="0">No Action</option>
                    <option value="1">Low</option>
                    <option value="2">Medium</option>
                    <option value="3">High</option>
                    <option value="4">OMG</option>
                </select>
            )
        }


        return (
            <div key={"showcr"} className={styles.rightbox}>
                <div className={styles.boxtitle}>Message Types</div>
                <div className={styles.boxhdrmt}>
                    <div>Message Type</div>
                    <div>Priority</div>
                    <div>Start Time</div>
                    <div>End Time</div>
                    <div>Active</div>
                </div>
                {mtlist.map((mt,i)=> {
                    const mtid = mt.msgtypeid || 0;
                    return (
                        <div key={'mt_'+i} className={styles.boxmt}>
                            <div>{mt.name}</div>
                            <div><ShowPriorities mt={mt}/></div>
                            <div><input type="text" maxLength="5" style={{width:"60px"}} placeholder={"HH:MM"} id={"mtstart_"+mtid} onBlur={onTimeBlur} value={mt.starttime} onChange={mtChange}></input></div>
                            <div><input type="text" maxLength="5" style={{width:"60px"}} placeholder={"HH:MM"} id={"mtend_"+mtid} onBlur={onTimeBlur} value={mt.endtime} onChange={mtChange}></input></div>
                            <div><input type="checkbox" id={"mtact_"+mtid} checked={mt.active==='Y'} onChange={mtChange}></input></div>
                        </div>
                    )
                })}
                <div style={{width:"280px",marginLeft:"5px",marginTop:"10px",paddingBottom:"20px",display:"flex"}}>
                    <GenButton width="120" buttontext="Save" disabled={!dirtymtypes} onclick={saveMT} />
                </div>
            </div>
        )
    }

    const handleMenuChangeOK = (e) => {
        if (menuid==='m1') {
            setMatrix(values => ({...values,credentials:origcreds}));
            setDirtyCreds(false);
        }
        if (menuid==='m2') {
            setMatrix(values => ({...values,specialties:origspecs}));
            setDirtySpecs(false);
        }
        if (menuid==='m3') {
            setMatrix(values => ({...values,infotypes:originfotypes}));
            setDirtyITypes(false);
        }
        if (menuid==='m5') {
            setMatrix(values => ({...values,msgtypes:origmsgtypes}));
            setDirtyMTypes(false);
        }
        setMenuID(newmenuid);
        setShowChangeMenu(false);
    }

    const handleMenuChangeCancel = (e) => {
        setShowChangeMenu(false);
    }

    const handleMenuChange = (e) => {
        let mid = e.target.id;
        newmenuid = mid;
        if (menuid==='m1' && dirtycreds===true) {
            setShowChangeMenu(true);
            return;
        }
        if (menuid==='m2' && dirtyspecs===true) {
            setShowChangeMenu(true);
            return;
        }
        if (menuid==='m3' && dirtyitypes===true) {
            setShowChangeMenu(true);
            return;
        }

        if (menuid==='m5' && dirtymtypes===true) {
            setShowChangeMenu(true);
            return;
        }
        setMenuID(mid);
    }

    const LeftSide = () => {
        return (
            <React.Fragment>
                <div className={styles.menu}>
                    <div className={styles.menutop}>Select List to Edit</div>
                    <div id="m1" onClick={handleMenuChange} className={menuid !== 'm1' ? styles.menuitem : styles.menuitemsel}>Credentials</div>
                    <div id="m2" onClick={handleMenuChange} className={menuid !== 'm2' ? styles.menuitem : styles.menuitemsel}>Specialties</div>
                    <div id="m4" onClick={handleMenuChange} className={menuid !== 'm4' ? styles.menuitem : styles.menuitemsel}>Systems</div>
                    <div id="m3" onClick={handleMenuChange} className={menuid !== 'm3' ? styles.menuitem : styles.menuitemsel}>Add. Message Items</div>
                    <div id="m5" onClick={handleMenuChange} className={menuid !== 'm5' ? styles.menuitem : styles.menuitemsel}>Message Types</div>
                </div>
            </React.Fragment>
        )
    }

    const RightSide = () => {
        return (
            <React.Fragment>
                {menuid==="m1" && ShowCredentials()}
                {menuid==="m2" && ShowSpecialties()}
                {menuid==="m3" && ShowInfoTypes()}
                {menuid==="m4" && ShowSystems()}
                {menuid==="m5" && ShowMsgTypes()}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
        <div className={styles.title}>System Lists Management</div>
            <div className={styles.main}>
                <div><LeftSide /></div>
                <div>{RightSide()}</div>
                {showdelcred && <GenConfirm onCancel={saveCredsCancel} 
                    onConfirm={saveCredsOK}
                    confirmText={"Ok"} 
                    width={500}
                    text={"Deleting Credentials will also remove them from Providers.\nAre you sure you want to delete?"}
                />}
                {showdelspec && <GenConfirm onCancel={saveSpecsCancel} 
                onConfirm={saveSpecsOK}
                confirmText={"Ok"} 
                width={500}
                text={"Deleting Specialties will also remove them\nfrom Providers and Practices.\nAre you sure you want to delete?"}
                />}
                {showdelsys && <GenConfirm onCancel={saveSysCancel} 
                onConfirm={saveSysOK}
                confirmText={"Ok"} 
                width={500}
                text={"Deleting Systems will also remove them\nfrom Users and Practices.\nAre you sure you want to delete?"}
                />}
                {showdelit && <GenConfirm onCancel={saveITCancel} 
                onConfirm={saveITOK}
                confirmText={"Ok"} 
                width={500}
                text={"Deleting Additional Message Items will remove saved\nItems from Practices and existing Messages!\nAre you SURE you want to delete?\nNOTE: There is NO undo for this!"}
                />}
                {showchangemenu && <GenConfirm onCancel={handleMenuChangeCancel} 
                onConfirm={handleMenuChangeOK}
                confirmText={"Ok"} 
                width={500}
                text={"There are unsaved changes.\nPress OK to lose changes.\nPress Cancel to allow saving changes."}
                />}

            </div>
        </React.Fragment>
    )
}

export default SystemLists;