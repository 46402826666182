import React from "react";
import styles from "./EscalatedTickets.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import AddNote from "../../components/AddNote";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITIES, Date12, calculateAgeInYears, PRIORITY_HOLDING} from "../../AppConstants";

const EscalatedTickets = (props) => {
    const [tickets,setTickets] = React.useState([]);
    const [ticket,setTicket] = React.useState(null);
    const [ipstate,setIPState] = React.useState(-1);
    const [showticket,setShowTicket] = React.useState(false);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [birthday, setBirthday] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [holding, setHolding] = React.useState(0);
    const [entries, setEntries] = React.useState([]);
    const [workstates,setWorkStates] = React.useState({});
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [searchdone,setSearchDone] = React.useState(false);
    const [shownote,setShowNote] = React.useState(false);
    const [showhold,setShowHold] = React.useState(false);
    const [showclose,setShowClose] = React.useState(false);
    const [ischild,setIsChild] = React.useState(props.ischild || false);
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [ticketsortfield,setTicketSortField] = React.useState('');
    const [ticketsortorder,setTicketSortOrder] = React.useState('');

    const dummy = () => {}

    const onCancel = (e) => {
        const cb = props.oncancel || dummy;
        cb();
    }

    const readEscalated = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readescalated(props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setTickets(data.TICKETS);
        })

    }

    React.useEffect(()=> {
        readEscalated();
    },[refreshneeded])


    const handleSubmit = (id) => {
        // Call server component to retrieve list of entries based on form input
        // Simulated server call
        console.log('Form submitted with:', { firstName, lastName, birthday, phoneNumber });
        let haveentry = firstName.length > 0 | lastName.length > 0 | birthday.length > 0 | phoneNumber.length > 0;
        if (!haveentry) {
            alert("At least 1 item needs to be filled in. Cancelling search.");
            return;
        }
        setSelectedEntry(null);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        let sdob = "";
        if (birthday.length > 0) {
            sdob = CleanDate(birthday);
            console.log("DOB = "+sdob);
            if (!sdob.length) {
                alert("Invalid DOB.");
                return;
            }
        }
        let sphone = "";
        if (phoneNumber.length > 0) {
            sphone = PhoneTest(phoneNumber);
            if (!sphone.length) {
                alert("Invalid Phone Number.");
                return;
            }
        }
        dataservice.searchtickets(holding,firstName,lastName,sdob,sphone).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setEntries(data.TICKETS);
            let states = data.STATES || [];
            let indexstates = {};
            states.forEach((st)=> {
                indexstates[st.WorkFlowStateID] = st.Name;
            })
            setWorkStates(indexstates);
            setSearchDone(true);
        })
      };
    
    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setTicket(data.TICKET);
            const wf = data.TICKET.workflow || [];
            wf.forEach((ws)=> {
                if (ws.buttontext.toLowerCase()==='in-progress') {
                    setIPState(ws.workflowstateid);
                }
            })
            setShowTicket(true);
        })
    };

    const ticketOnClose = (ticketid,stateid,priority) => {
        if (stateid===ipstate) {
            setShowTicket(false);
            let tid = ticket.ticketid;
            handleEntryClick({ticketid:tid})
        } else {
            setShowTicket(false);
            readEscalated();
        }
    }


    const cancelNote = () => {
        setShowNote(false);
    }

    const saveNote = (reason,cmt) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        //cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');


        let obj = {
            ticketid:selectedEntry.ticketid,
            msgid:selectedEntry.msgid,
            msgtype:selectedEntry.msgtype,
            msgdt:"",
            respid:props.globals.useraccountid,
            resptype:'R',
            uaid:props.globals.useraccountid,
            actionid:ACTIONADDNOTE,
            note:cmt
        }
        dataservice.addticketnote(obj).then((data) => {
                if (data.ERROR) {
                    alert(data.ERROR);
                } else {
                    selectedEntry.notes = data.NOTES;
                    setShowNote(false);
                }
            })
        
    }

    const setAddNote = (e) => {
        setShowNote(true);
    }

    const SetCloseOK = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.setticketevent(selectedEntry.ticketid,selectedEntry.finalstate,selectedEntry.priority,props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                let newentries = JSON.parse(JSON.stringify(entries));
                let selid = selectedEntry.ticketid;
                newentries = newentries.filter(obj=>obj.ticketid !== selid);
                setSelectedEntry(null);
                setEntries(newentries)
            }
            setShowClose(false);
        })
    }

    const SetCloseCancel = (id) => {
        setShowClose(false);
    }

    const SetClose = (id) => {
        setShowClose(true);
    }

    const SetHoldOK = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.settickethold(selectedEntry.ticketid,1,props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                selectedEntry.priority = PRIORITY_HOLDING;
            }
            setShowHold(false);
        })
    }

    const SetHoldCancel = (id) => {
        setShowHold(false);
    }

    const SetHold = (id) => {
        setShowHold(true);
    }

    const ShowTickets = () => {
        if (tickets.length === 0) {
            return (
                <React.Fragment>
                    <div>No Available Tickets</div>
                </React.Fragment>
            )
        }
        
        if (ticketsortfield.length > 0) {
            tickets.sort((a,b)=> {
                const fldA = a[ticketsortfield];
                const fldB = b[ticketsortfield];
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return ticketsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = ticketsortfield;
            let torder = ticketsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setTicketSortField(fld);
            setTicketSortOrder(torder);
        }

        return (
            <React.Fragment>
                <div className={styles.listrowhdr}>
                    <div id="ticketid" onClick={handleSortClick}>Ticket #{ticketsortfield==='ticketid' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='ticketid' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="msgname" onClick={handleSortClick}>Message Type{ticketsortfield==='msgname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='msgname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="priority" onClick={handleSortClick}>Priority{ticketsortfield==='priority' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='priority' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="createdate" onClick={handleSortClick}>Created Date{ticketsortfield==='createdate' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='createdate' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="firstname" onClick={handleSortClick}>First Name{ticketsortfield==='firstname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='firstname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="lastname" onClick={handleSortClick}>Last Name{ticketsortfield==='lastname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='lastname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="dob" onClick={handleSortClick}>DOB{ticketsortfield==='dob' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='dob' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="callbackphone" onClick={handleSortClick}>Phone{ticketsortfield==='callbackphone' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='callbackphone' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="pracname" onClick={handleSortClick}>Practice{ticketsortfield==='pracname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='pracname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                </div>
                <div style={{height:"10px"}}></div>
                {tickets.map((entry,i) => {
                    const ptage = calculateAgeInYears(entry.dob);
                    let phones = "";
                    let bg = null;
                    if (entry.priority===PRIORITY_HOLDING && holding===0) {
                        bg = "yellow";
                    }
                    let rowbg = "white";
                    if (entry===selectedEntry) {
                        rowbg = "lightgreen";
                    }
                    if (entry.callbackphone.length > 0) {
                        phones = formatPhoneNumber(entry.callbackphone);
                    }
                    if (entry.secondaryphone.length > 0) {
                        if (phones.length > 0) phones += " , "
                        phones += formatPhoneNumber(entry.secondaryphone);
                    }
                    return (
                        <div key={"phone_"+i} className={styles.listrow} onClick={()=>handleEntryClick(entry)}>
                            <div>{entry.ticketid}</div>
                            <div>{entry.msgname}</div>
                            <div style={{backgroundColor:bg}}>{PRIORITIES[entry.priority]}</div>
                            <div>{Date12(entry.createdate)}</div>
                            <div>{entry.firstname}</div>
                            <div>{entry.lastname}</div>
                            <div>{DateOnly(entry.dob)} ({ptage})</div>
                            <div>{phones}</div>
                            <div>{entry.pracname}</div>
                        </div>
                    )
                    
                })}
            </React.Fragment>
        )
    }


    const ShowButtons = () => {

        if (selectedEntry === null) {
            return null;
        }
        let showhold = false;
        if (holding===0 && selectedEntry.priority !== PRIORITY_HOLDING) {
            showhold = true;
        }
        let isescalated = selectedEntry.ticketname==='ESCALATE';
        
        const note = selectedEntry.notes || "";
        let notes = note.split('\n').filter(Boolean);
        return (
            <React.Fragment>
                <div style={{display:"flex",marginTop:"12px",marginLeft:"10px"}}>
                    <div style={{display:"flex"}}>
                        <div><GenButton buttontext="Add Note" width="110" onclick={setAddNote} fontsize="16px" /></div>
                        {showhold && <div style={{marginLeft:"16px"}}><GenButton buttontext="Place on Hold" width="110" onclick={SetHold} fontsize="16px" /></div>}
                        {isescalated && <div style={{display:"flex"}}>
                            <div style={{marginLeft:"16px"}}><GenButton buttontext="Put in Queue" width="110" onclick={onCancel} fontsize="16px" /></div>
                            <div style={{marginLeft:"16px"}}><GenButton buttontext="Take Ownership" width="110" onclick={onCancel} fontsize="16px" /></div>
                            </div>}
                        <div style={{marginLeft:"16px"}}><GenButton buttontext="Close Ticket" width="110" onclick={SetClose} fontsize="16px" /></div>
                        {ischild && <div style={{marginLeft:"16px"}}><GenButton buttontext="Cancel Call" width="110" onclick={onCancel} fontsize="16px" /></div>}
                    </div>

                    <div style={{width:"700px",marginLeft:"20px",fontSize:"14px", display: 'flex', flexDirection: 'column',alignItems:"flex-start",fontWeight:"bold"}}>Notes:
                        {notes.map((n,i)=> {
                            return (
                                <div key={i} style={{fontWeight:"normal"}}>{n}</div>
                            )
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div><div className={styles.title}>Escalated Tickets</div>
                <ShowTickets />
                <ShowButtons />
                {showticket && <TicketDetail globals={props.globals} methods={props.methods} allowpriorityedit={true} ticket={ticket} doClose={ticketOnClose} />}
            </div>

        </React.Fragment>

    )
}

export default EscalatedTickets;