import React from "react";
import styles from "./Dashboard.module.scss";
import { DataService } from "../../store/DataService";
import {formatPhoneNumber, Date12} from "../../AppConstants";
import MessageAction from "./MessageAction";
//import ContactScreen from "./ContactScreen";
import ContactPopup from "./ContactPopup";
import {useNavigate} from 'react-router-dom'; 
import useKeyPress from "./../../components/useKeyPress";
import GenMsgBox from "../../components/GenMsgBox";

//var alertrefresh = false;
//var alertdelay = false;
//var alertseconds = 0;

const Dashboard = (props) => {
    const [locations,setLocations] = React.useState([]);
    const [locid,setLocID] = React.useState(props.globals.locationid);
    const [provid,setProvID] = React.useState(0);
    const [practiceproviders,setPracticeProviders] = React.useState({oncall:[],providers:[]});
    const [callnotes,setCallNotes] = React.useState([]);
    const [alertdata,setAlertData] = React.useState({});
    const [showalert,setShowAlert] = React.useState(false);
    const [showbackups,setShowBackups] = React.useState(false);
    const [havebackups,setHaveBackups] = React.useState(false);
    const [alertaborted,setAlertAborted] = React.useState(false);
    const [showalloncall,setShowAllOnCall] = React.useState(false);
    const tmoutRef = React.useRef();
    const history = useNavigate();
    const [alertrefresh,setAlertRefresh] = React.useState(false);
    const [alertdelay,setAlertDelay] = React.useState(false);
    const [alertseconds,setAlertSeconds] = React.useState(0);
    const [alertmsgid,setAlertMsgID] = React.useState(props.alertmsg);
    const [usermatrix,setUserMatrix] = React.useState({mtskills:[],pracs:[],alerts:[],ERROR:""});
    const checkalertinterval = 15000;

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getlocations(props.globals.practiceid).then((data) => {
            data.sort(compareNames);
            setLocations(data);
            let lid = props.globals.locationid;
            if (lid < 1) {
                lid = props.globals.practice.primarylocationid;
                if (lid===undefined) lid=0;
            }
            setLocID(lid);
            
        })
    },[props.globals.sessionguid,props.globals.practiceid,props.globals.locationid])

    
    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getpracticeproviders(props.globals.practiceid).then((data) => {
            let err = data.ERROR || "";
            if (err.length) {
                alert(err);
            } else {
                setPracticeProviders(data);
                let toncall = data.oncall || [];
                let bkcount = 0;
                toncall.forEach((obj)=> {
                    if (obj.bkuseq > 1) {
                        bkcount += 1;
                    }
                })
                setHaveBackups(bkcount > 0);
            }
        })
    },[props.globals.sessionguid,props.globals.practiceid])
    

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.getcallnotes(props.globals.practiceid).then((data) => {
            if (props.globals.intext===1) {
                setAlertRefresh(true);
                let x = parseInt(alertmsgid);
                if (x > 0) {
                    doTimerStuff();
                }
            }
            let err = data.ERROR || "";
            if (err.length) {
                alert(err);
            } else {
                setCallNotes(data.notes);
            }
        })
    },[props.globals.sessionguid,props.globals.practiceid,props.globals.intext])

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        if (props.globals.practiceid < 1) {
            dataservice.inituserdashboard(props.globals.useraccountid).then((data) => {
                if (data.ERROR) {
                    if (data.ERROR.length) {
                        alert(data.ERROR);
                        return;
                    }
                }
                setUserMatrix(data.MATRIX);
            })    
        }
    },[props.globals.useraccountid,props.globals.practiceid])


    React.useEffect(() => {
        var isOnOff = 0;
        const timer = setInterval(()=> {
            let newas = alertseconds + 1;
            setAlertSeconds(newas);
            if (props.globals.intext===1) {
                if (alertrefresh) {
                    if (alertseconds > 1) {
                        setAlertSeconds(0);
                        doTimerStuff();
                    }
                } else {
                    if (alertdelay) {
                        if (alertseconds > 5) {
                            setAlertDelay(false);
                            setAlertSeconds(0);
                            setAlertRefresh(true);
                        }
                    }
                }
            }
        },checkalertinterval)
        return ()=> clearInterval(timer);
    },[props.globals.intext,alertdelay,alertseconds,alertrefresh])

    const onKeyPress = (event) => {
        console.log(`key pressed: ${event.key}`);
        if (event.key==='F2' && props.globals.practiceid > 0) {
            event.preventDefault();
            history('/takemsg');
        }
        if (event.key==='F6') {
            event.preventDefault();
            history('/intviewurg');
        }
        if (event.key==='F9' && props.globals.practiceid > 0) {
            event.preventDefault();
            history('/extviewpm');
        }
        if (event.key==='F5') {
            event.preventDefault();
        }
      };
    
    useKeyPress(['F2','F5','F6','F9'], onKeyPress);

    const doTimerStuff = () => {
        //console.log("Doing timer stuff");
        //console.log("skipalerts = "+props.globals.skipalerts);
        //console.log("alert refresh = "+alertrefresh);

        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.checkalerts(props.globals.useraccountid,1,alertmsgid).then((data) => {
            //alertrefresh = false;
            setAlertRefresh(false);
            setAlertMsgID(0);
            if (data.ERROR) {
                alert(data.ERROR);
                
            } else {
                //console.log("alert count = "+data.COUNT);
                if (data.COUNT > 0) {
                    //alertseconds = 0;
                    //alertdelay = false;
                    setAlertSeconds(0);
                    setAlertDelay(false);
                    setAlertData(data.QDATA);
                    setShowAlert(true);
                } else {
                    setAlertRefresh(true);
                }
            }
        });
       
    }

    const handleRowSelect = (e) => {
        const p = e.currentTarget;
        const lid = p.id;
        setLocID(lid);
    }

    const compareNames = (a,b) => {
        if (!a || !b) return 0;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    }

    const ShowLocations = () => {
        let curlocid = "" + locid;
        //console.log("ShowLocations, locid="+locid+",curlocid="+curlocid+",globallocid="+props.globals.locationid);
        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Current Locations</div>
                <div className={styles.locations}>
                <table className={styles.loctable}>
                    <thead>
                    <tr><th>Name</th><th>Acct #</th><th>City, State</th></tr>
                    </thead><tbody>
                    {locations.map((loc,i) => {
                        //console.log("locationid="+loc.locationid+",name="+loc.name);
                        if (loc.locationid===curlocid) {
                            return <tr key={i} id={loc.locationid} onClick={handleRowSelect} className={styles.locselect}><td className={styles.loccol1} style={{width:"360px"}}>{loc.name}</td><td style={{width:"90px"}}>{loc.accountnumber}</td><td style={{width:"310px"}}>{loc.city}, {loc.state}</td></tr>
                        } else {
                            return <tr key={i} id={loc.locationid} onClick={handleRowSelect} className={styles.location}><td className={styles.loccol1} style={{width:"360px"}}>{loc.name}</td><td style={{width:"90px"}}>{loc.accountnumber}</td><td style={{width:"310px"}}>{loc.city}, {loc.state}</td></tr>
                        }
                    })}
                    </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }

    const ShowLocationDetail = () => {
        let loc = findLocation(locid);
        if (!loc) {
            return (
                <React.Fragment>
                    <div className={styles.loctitle} style={{marginTop:"12px"}}>Location Contacts</div>
                    <div className={styles.locdetailbox}>
                        <div className={styles.locdetailrow} style={{fontWeight:"bold"}}>
                            <div>Method</div>
                            <div>Phone Number</div>
                        </div>
                    </div>
                </React.Fragment>
            )            
        }
        let phones = loc.phone || [];
        let directions = loc.directions || "";
        //console.log("ShowLocationDetail");
        let add1 = loc.address1 || "";
        let add2 = loc.address2 || "";
        let csz = loc.city + ", " + loc.state + " " + loc.zipcode;
        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Location Contacts</div>
                <div className={styles.locdetailbox}>
                    <div className={styles.locdetailrow} style={{fontWeight:"bold"}}>
                        <div>Method</div>
                        <div>Phone Number</div>
                    </div>
                    {phones.map((phone,i)=> {
                        let pn = phone.phonenumber;
                        pn = formatPhoneNumber(pn);
                        return (<div key={"pn_"+i} className={styles.locdetailrow}>
                            <div>{phone.contacttypename}</div>
                            <div>{pn}</div>
                        </div>)
                    })}
                    <hr/>
                    <div className={styles.locaddressblock}>
                        {add1.length > 0 && <div className={styles.locaddressrow}><div className={styles.locaddresslabel}>Address:</div><div>{add1}</div></div>}
                        {add2.length > 0 && <div className={styles.locaddressrow}><div className={styles.locaddresslabel}></div><div>{add2}</div></div>}
                        <div className={styles.locaddressrow}><div className={styles.locaddresslabel}>City, State Zip:</div><div>{csz}</div></div>
                    </div>
                    {directions.length > 0 && 
                        <div><hr/>
                            <div style={{fontWeight:"bold"}}>Directions:</div>
                            <div>{directions}</div>
                        </div>}
                </div>
            </React.Fragment>
        )
    }

    const findLocation = (lid) => {
        let newloc = null;
        locations.forEach((loc)=> {
            if (loc.locationid===lid) {
                newloc = loc;
            }
        })
        return newloc;
    }

    const findHoursStart = (loc,dowid) => {
        var st = "";
        if (loc) {
            if (loc.hours) {
                loc.hours.every((hr)=> {
                    if (hr.dowid===dowid) {
                        st = hr.starttime;
                        return false;
                    }
                    return true;
                })
                //if (loc.hours.length > dowid) {
                //    st = loc.hours[dowid].starttime;
                //}
            }
        }
        return st;
    }

    const findHoursEnd = (loc,dowid) => {
        var et = "";
        if (loc) {
            if (loc.hours) {
                loc.hours.every((hr)=> {
                    if (hr.dowid===dowid) {
                        et = hr.endtime;
                        return false;
                    }
                    return true;
                })
                //if (loc.hours.length > dowid) {
                //    et = loc.hours[dowid].endtime;
                //}
            }
        }
        return et;
    }

    const HourRow = (day) => {
        let hrst = findHoursStart(day.loc,day.dowid);
        let hrend = findHoursEnd(day.loc,day.dowid);
        let st = styles.hourrow;
        //console.log("ShowHourRow");

        return (
            <React.Fragment>
                <div key={"sh_"+day.dowid} className={st}>
                    <div>{day.dow}</div>
                    <div>{hrst}</div>
                    <div>{hrend}</div>
                </div>
            </React.Fragment>
        )
    }

    const ShowHours = () => {
        const days = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
        let loc = findLocation(locid);
        if (!loc) {
            return (
                <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Hours</div>
                <div className={styles.hourbox}>
                    <div className={styles.hourrow} style={{fontWeight:"bold"}}>
                        <div>Day</div><div>Start Time</div><div>End Time</div>
                    </div>
                </div>
                </React.Fragment>
            )
        }
        const cmt = loc.comments || "";
        //console.log("ShowHours");

        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Hours</div>
                <div className={styles.hourbox}>
                <div className={styles.hourrow} style={{fontWeight:"bold"}}>
                    <div>Day</div><div>Start Time</div><div>End Time</div>
                </div>
                {days.map((dow,i) => {
                    return (<HourRow key={"hr_"+i} dow={dow} dowid={i} loc={loc} />)
                })}
                <div style={{marginTop:"5px",borderTop:"1px solid black"}}>{cmt}</div>
                </div>
            </React.Fragment>
        )
    }

    const ShowOnCall = () => {
        const oncall = practiceproviders.oncall || [];
        const orggroups = practiceproviders.groups || [];
        let groups = {};
        orggroups.forEach((grp)=>{
            groups[grp.ocid] = {used:0,ocdesc:grp.ocdesc};
        })
        if (oncall.length < 1 && groups.length > 1) {
            return (
                <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>
                On-Call Providers

                </div>
                <div className={styles.oncallbox}>
                    <div style={{marginTop:"2px",fontSize:"16px"}}>No On-Call providers or groups.</div>
                </div>
                </React.Fragment>
            )
        }
        //console.log("ShowOnCall");

        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>
                On-Call Providers
                <span style={{fontSize:"14px",marginLeft:"10px"}}>
                    <label>
                    <input type="checkbox" name="cballoncall" checked={showalloncall} onChange={(e)=>{setShowAllOnCall(!showalloncall)}}></input>
                    Show all coverage
                    </label>
                </span>
                {havebackups && <span style={{fontSize:"16px",marginLeft:"26px"}}>
                    <label>
                    <input type="checkbox" name="cbbkuoncall" checked={showbackups} onChange={(e)=>{setShowBackups(!showbackups)}}></input>
                    Show Backups
                    </label>
                    </span>}
                </div>
                <div className={styles.oncallbox}>
                    <div className={styles.oncallrow} style={{fontWeight:"bold"}}>
                        <div>Provider or Group</div>
                        <div>OnCall Group</div>
                        <div>Start Time</div>
                        <div>End Time</div>
                    </div>
                    {oncall.map((obj,i) => {
                        let bkseq = obj.bkuseq || 0;
                        if (!showbackups && bkseq > 1) return null;
                        let pn = obj.homephone;
                        if (pn.length) {
                            pn = formatPhoneNumber(pn);
                            pn += " (Home)";
                        } else {
                            pn = obj.cellphone;
                            if (pn.length) {
                                pn = formatPhoneNumber(pn);
                                pn += " (Cell)";
                            }
                        }
                        let firstname = obj.firstname || '';
                        let lastname = obj.lastname || '';
                        let name = firstname + ' ' + lastname;
                        if (name.length > 25) {
                            name = name.substring(0,25) + '...';
                            //name = firstname.substring(0,3) + '. ' + lastname;
                            //if (name.length > 25) {
                            //    name = name.substring(0,23) + '..';
                            //}
                        }
                        let active = obj.active;
                        if (active==='undefined') active = 0;
                        active = parseInt(active);
                        if (!showalloncall && active===0) return null;
                        let rowstyle = styles.oncallrow;
                        groups[obj.ocallid].used = 1;
                        if (showbackups && bkseq > 1) rowstyle = styles.oncallrowbackup;
                        if (active === 0 && showalloncall===true) {
                            rowstyle = styles.oncallrowactive;
                            if (bkseq > 1) rowstyle = styles.oncallrowactivebackup;
                        }
                        
                        let ocdesc = obj.ocdesc || '';
                        let sdate = obj.sdate || ' -';
                        let edate = obj.edate || ' -';
                        sdate = obj.sdate.split(' ')[1];
                        edate = obj.edate.split(' ')[1];
                        return (
                            <div key={"ocr_"+i} className={rowstyle}>
                                <div>{name}</div>
                                <div>{ocdesc}</div>
                                <div>{sdate}</div>
                                <div>{edate}</div>
                            </div>
                        )
                    })}
                    {Object.keys(groups).map((ky,i) => {
                        let obj = groups[ky];
                        if (obj.used===1) return null;
                        let rowstyle = styles.oncallrowempty;
                        return (
                            <div key={"ocr_"+i} className={rowstyle}>
                                <div>No Providers</div>
                                <div>{obj.ocdesc}</div>
                                <div></div>
                                <div></div>
                            </div>                            
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    const ShowLegend = () => {
        return (
            <div className={styles.ocrlegend}>
                <div>Legend:</div>
                <div className={styles.ocrnormal}>Primary Providers</div>
                <div className={styles.ocractive}>Past/Future Providers</div>
                <div className={styles.ocrbackup}>Backup Providers</div>
                <div className={styles.ocractivebackup}>Past/Future Backup Providers</div>
                <div className={styles.ocrempty}>No Providers</div>
            </div>
        )
    }

    const handleProviderDetail = (e) => {
        const pid = e.target.id;
        setProvID(pid);
    }

    const findProvider = (pid) => {
        let p = null;
        for (let i = 0;i<practiceproviders.providers.length;i++) {
            let obj = practiceproviders.providers[i];
            if (obj.providerid===pid) {
                p = obj;
                break;
            }
        }
        return p;
    }
    const ShowProviderDetail = () => {
        const prov = findProvider(provid);
        if (!prov) {
            return (
                <React.Fragment>
                    <div className={styles.loctitle} style={{marginTop:"12px"}}>Provider Contacts</div>
                    <div className={styles.provdetailbox}>
                    </div>
                </React.Fragment>
            )
        }
        const phones = prov.phones || [];
        let provname = prov.firstname;
        let mi = prov.middleinitial + '';
        mi = mi.trim()
        if (mi.length > 0) {
            provname += " " + mi + ".";
        }
        provname += " " + prov.lastname;
        //console.log("ShowProviderDetail");

        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Provider Contacts ({provname})</div>
                <div className={styles.provdetailbox}>
                    <div className={styles.provdetailrow} style={{fontWeight:"bold"}}>
                        <div>Phone Number</div>
                        <div>Contact Type</div>
                        <div>Pager Email</div>
                    </div>
                    {phones.map((phone,i) => {
                        let pnum = phone.phonenumber;
                        if (phone.description !== 'Email') {
                            pnum = formatPhoneNumber(pnum);
                        }
                        return (
                            <React.Fragment key={"pnum_"+i}>
                            <div key={"pn2_"+i} className={styles.provdetailrow}>
                            <div>{pnum}</div>
                            <div>{phone.description}</div>
                            <div>{phone.pageremail}</div>
                            </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    const ShowProviders = () => {
        const plist = practiceproviders.providers || [];
        

        if (plist.length < 1) {
            return (
                <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Providers</div>
                <div className={styles.provbox}>
                    No Providers for this practice.
                </div>
                </React.Fragment>
            )
        }
       //console.log("ShowProviders");

        return (
            <React.Fragment>
                <div className={styles.loctitle} style={{marginTop:"12px"}}>Providers</div>
                <div className={styles.provbox}>
                {plist.map((obj,i) => {
                    let name = obj.firstname;
                    let mi = obj.middleinitial + '';
                    mi = mi.trim();
                    if (mi.length > 0) {
                        name += " " + mi + ".";
                    }
                    name += " " + obj.lastname;
                    name = name.replace("\n","");
                    name = name.replace("<br/>","");
                    let st = styles.provrow;
                    if (obj.providerid===provid) {
                        st = styles.provrowsel;
                    }
                    if (obj.activeflag !== 'Y' || obj.practicemember !== 'Y') return null;
                    return (
                        <div key={"nm_"+i} className={st}>
                            <div key={"pdetail_"+i} id={obj.providerid} onClick={handleProviderDetail}>{name}</div>
                        </div>
                    )
                })}
                </div>
            </React.Fragment>
        )
    }

    const ShowCallNotes = () => {

        if (!callnotes.length) {
            return (
                <React.Fragment>
                <div className={styles.loctitle} style={{marginLeft:"12px"}}>Call Notes</div>
                    <div className={styles.callnotebox} style={{marginLeft:"12px"}}>
                        <div></div>
                    </div>
                </React.Fragment>
            )
        }
        //console.log("ShowCallNotes");
        return (
            <React.Fragment>
            <div className={styles.loctitle} style={{marginLeft:"12px"}}>Call Notes</div>
            <div className={styles.callnotebox} style={{marginLeft:"12px"}}>
                <ul>
                {callnotes.map((note,i)=> {
                    if (note.oncallid===0 && note.providerid===0) {
                        let ntext = note.notes || "";
                        if (ntext.length) {
                            let lines = ntext.split('\r\n');
                            return <li key={"cnp_"+i} className={styles.callnoterow}>
                            {lines.map((line,j)=> {
                                return (<div key={"cn__"+i+"_"+j} dangerouslySetInnerHTML={{ __html:line}}></div>)
                            })}
                            </li>
                        } else {
                            return null;
                        }
                        
                    } else {
                        return null;
                    }
                })}
                </ul>
            </div>

            </React.Fragment>
        )
    }
    const dummyReset = (smsg='') => {
        const pobj = {sessionexpired:false,dirtydata:false,oncallid:0,useraccountid:0,sysusermsg:smsg}
		props.methods.SetObject(pobj);
        history("/");
    }

    const closeAlert = (action) => {
        setShowAlert(false);
        if (props.globals.intext === 1) {
            if (action==="undefined" || action===undefined) action = "";
            if (action === 'abort') {
                setAlertAborted(true);
            }
            if (action === 'logout') {
                let reset = props.resetlogin || dummyReset;
                reset('Logged out because alert timeout occurred.');
            } 
            if (action.indexOf("end:") === 0) {
                const sa = action.split(':');
                const msgid = sa[1];
                const dataservice = new DataService({guid:props.globals.sessionguid});
                dataservice.resetalert(props.globals.useraccountid,msgid).then((data)=> {
                    if (data.ERROR) {
                        alert(data.ERROR);
                    } else {
                    }
                })
            }
            else {
                //rem out 3/21/23 to see if delay between alerts is better
                //setAlertDelay(true);
                setAlertSeconds(0);    
            }
            let pth = window.location.pathname;
            if (pth.indexOf("/home") >= 0) {
                history("/");
            }
        }
    }

    const readAlertData = (msgid) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readalert(msgid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setAlertData(data.QDATA);
            setShowAlert(true);
        })

    }

    const updateAlert = (e) => {
        setShowAlert(false);
        if (props.globals.intext === 1) {
            readAlertData(alertdata.messageid);
        }
    }

    /*
var alertrefresh = false;
var alertdelay = false;
var alertseconds = 0;
    */
    const ShowAlertInfo = () => {
        let ar = alertrefresh ? "True" : "False";
        let ad = alertdelay ? "True" : "False";
        let secs = alertseconds * 30;
        const showinfo = false; // Set to True to display Alert Info

        if (!showinfo) {
            return null;
        }
        return (
            <div style={{fontWeight:"bold",fontSize:"14px"}}>
                <div style={{marginTop:"36px"}}>Alert Refresh {ar}</div>
                <div>Alert Delay {ad}</div>
                <div>Alert Seconds {secs}</div>
            </div>
        )
    }

    const ShowUserDash = () => {
        if (!usermatrix) return null;
        let skills = usermatrix.mtskills || [];
        let pracs = usermatrix.pracs || [];
        let alerts = usermatrix.alerts || [];

        return (
            <div className={styles.udash}>
                <div className={styles.udashcol}>
                    <div className={styles.udashhdr}>Message Type Skills</div>
                    {skills.map((o,i)=> {
                        return <div key={i}>{o.name}</div>
                    })}
                </div>
                {/*}
                <div className={styles.udashcol} style={{overflowY:"auto"}}>
                    <div className={styles.udashhdr} style={{maxWidth:"320px"}}>Enabled Practices</div>
                    {pracs.map((o,i)=> {
                        return <div key={i}>{o.name}</div>
                    })}
                </div>
                <div className={styles.udashcol} style={{overflowY:"auto"}}>
                    <div className={styles.udashhdr}>Active tickets</div>
                    {pracs.map((o,i)=> {
                        return <div key={i}>{o.total}</div>
                    })}
                </div>
                {*/}
                <div className={styles.udashcol2}>
                    <div  className={styles.udashprac}>
                        <div className={styles.udashhdr} style={{maxWidth:"320px"}}>Enabled Practices</div>
                        <div className={styles.udashhdr}>Active tickets</div>
                    </div>
                    <div className={styles.udashprac} style={{overflowY:"auto"}}>
                        {pracs.map((o,i)=> {
                            let tkcnt = o.avail > 0 ? o.avail : 0;
                            return <React.Fragment key={i}>
                                <div>{o.name}</div>
                                <div style={{paddingLeft:"40px"}}>{tkcnt}</div>
                            </React.Fragment>
                        })}
                    </div>
                </div>
                <div className={styles.udashcol2}>
                    <div className={styles.udashhdr}>Alerts</div>
                    <div className={styles.udashalerts} style={{overflowY:"auto"}}>
                        <div style={{fontWeight:"bold"}}>Account</div>
                        <div style={{fontWeight:"bold"}}>Account Name</div>
                        <div style={{fontWeight:"bold"}}>When</div>
                        {alerts.map((o,i)=> {
                            return <React.Fragment key={i}>
                                <div>{o.acctnum}</div>
                                <div style={{maxWidth:"330px"}}>{o.pracname}</div>
                                <div>{Date12(o.deldate)}</div>
                            </React.Fragment>
                        })}
                    </div>
                </div>
            </div>
        )
    }

    if (showalert) {
        return (
            <ContactPopup globals={props.globals} methods={props.methods} alertdata={alertdata} msgID={alertdata.messageid} onUpdate={updateAlert} onClose={closeAlert} />
        )
    }

    const abortOK = (e) => {
        setAlertAborted(false);
    }
    if (alertaborted) {
        return (
            <GenMsgBox text="Alert closed. You are no longer the owner of the alert" onOK={abortOK} />
        )
    }
    if (props.globals.practiceid < 1) return (
        <>
        <ShowAlertInfo />
        <ShowUserDash />
        </>
    );
    return (
        <React.Fragment>
        
        <ShowCallNotes />
        <div className={styles.maintop}>
            <div>
                <div style={{width:"100%"}}>
                    {ShowLocations()}
                </div>
            </div>
            <div>
                <div style={{width:"100%"}}>
                    <ShowLocationDetail />                    
                </div>
            </div>
            <div>
                <div style={{width:"100%"}}>
                    <ShowHours />
                </div>
            </div>
        </div>
        <div className={styles.mainbottom}>
            <div>
                <div style={{width:"100%"}}>
                    {ShowProviders()}
                </div>
            </div>
            <div>
                <div style={{width:"100%"}}>
                    <ShowOnCall />
                </div>
            </div>
            <div>
                <ShowLegend />
            </div>
            <div>
                <ShowAlertInfo />
            </div>

        </div>
        </React.Fragment>
    )
}

export default Dashboard;